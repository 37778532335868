// If this experiment wins, we will include this into .yml file

import { useMemo } from 'react'

import Link from 'next/link'

import { Button } from '@getethos/ethos-design-system-v2'

import Markdown from '@/components/global/Markdown'

import useDetectScreenSize from '@/hooks/useDetectScreenSize'

import styles from './TrustageLandingPageValueProps.module.scss'
import { Cards } from './components/Cards'

export const TrustageLandPageValueProps = () => {
  const { isLaptopAndUp } = useDetectScreenSize()

  const CONTENT = useMemo(
    () => ({
      TITLE: `Protect your loved ones with the ${
        isLaptopAndUp ? '<br/>' : ''
      } **#1** life insurance provider`,
      CARDS: [
        {
          image: {
            src: 'https://res.cloudinary.com/getethos/image/upload/v1732543839/children_tuition_costs_swyfpj.png',
            alt: '',
          },
          title: 'Children’s tuition costs',
          description: 'Ensure your kids get the education they deserve.',
        },
        {
          image: {
            src: 'https://res.cloudinary.com/getethos/image/upload/v1732543839/debt_mortgage_bozcum.png',
            alt: '',
          },
          title: 'Debt & mortgage',
          description:
            'Pay off debts like credit card bills, loans, and mortgages',
        },
        {
          isLimitedTimeOffer: true,
          image: {
            src: 'https://res.cloudinary.com/getethos/image/upload/v1732543839/free_estate_planning_uhfzcv.png',
            alt: '',
          },
          title: 'Free Estate Planning',
          description:
            'Protect your family with a free will, trust and more. ($898 value)',
        },
      ],
      subTitle: 'Get instant, same-day coverage in 10 minutes',
      button: {
        label: 'Learn more',
        url: '/how-it-works/',
      },
    }),
    [isLaptopAndUp]
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <Markdown input={CONTENT.TITLE} />
      </div>
      <Cards cards={CONTENT.CARDS} />
      <div className={styles.bottomContent}>
        <p
          className={` ${
            isLaptopAndUp ? 'display-m-med' : 'display-s-med'
          } !m-0 text-[#054742]`}
        >
          Get instant, same-day coverage in 10 minutes
        </p>
        <Link href={CONTENT.button.url}>
          <Button
            buttonTitle="Learn More"
            variant="primary"
            size="md"
            className={styles.button}
          />
        </Link>
      </div>
    </div>
  )
}
