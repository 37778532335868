export type Spacing = [string, string, string, string] // Check tailwind.config safelist for available values.

export const getResponsiveSpacingTopClasses = (
  spacingTop?: [string, string, string, string]
): string => {
  if (!spacingTop) return ''

  return `mt-${spacingTop[0]} sm:mt-${spacingTop[1]} md:mt-${spacingTop[2]} lg:mt-${spacingTop[3]}`
}
export const getResponsiveSpacingBottomClasses = (
  spacingBottom?: [string, string, string, string]
): string => {
  if (!spacingBottom) return ''

  return `mb-${spacingBottom[0]} sm:mb-${spacingBottom[1]} md:mb-${spacingBottom[2]} lg:mb-${spacingBottom[3]}`
}
