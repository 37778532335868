import { FC } from 'react'

import classNames from 'classnames'

import Markdown from '@/components/global/Markdown'

import styles from './Cards.module.scss'

interface ICard {
  image: {
    src: string
    alt: string
  }
  title: string
  description: string
  isLimitedTimeOffer?: boolean
}

type CardsProps = {
  cards: Array<ICard>
}

const TimeSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.4164 6.74574H9.16637V11.7457L13.1247 14.1207L13.7497 13.0957L10.4164 11.1207V6.74574ZM14.4472 1.5874L18.2864 4.79157L17.2197 6.07074L13.378 2.86824L14.4472 1.5874ZM5.5522 1.5874L6.62053 2.8674L2.78053 6.07074L1.71387 4.79074L5.5522 1.5874ZM9.9997 3.4124C5.85803 3.4124 2.4997 6.77074 2.4997 10.9124C2.4997 15.0541 5.85803 18.4124 9.9997 18.4124C14.1414 18.4124 17.4997 15.0541 17.4997 10.9124C17.4997 6.77074 14.1414 3.4124 9.9997 3.4124ZM9.9997 16.7457C6.78303 16.7457 4.16637 14.1291 4.16637 10.9124C4.16637 7.69574 6.78303 5.07907 9.9997 5.07907C13.2164 5.07907 15.833 7.69574 15.833 10.9124C15.833 14.1291 13.2164 16.7457 9.9997 16.7457Z"
        fill="white"
      />
    </svg>
  )
}

export const Cards: FC<CardsProps> = ({ cards }) => {
  return (
    <div className="flex w-full flex-col items-stretch justify-center gap-10 md:flex-row  md:items-stretch">
      {cards.map((card, index) => (
        <>
          <div
            key={`${card.title}_${index}`}
            className="mx-auto w-full max-w-[400px]"
          >
            <div className="relative">
              <img
                src={card.image.src}
                alt={card.image.alt}
                className="w-full max-w-[300px]"
              />
            </div>
            <div className="display-m-med text-[#054742] [&>p]:mb-0 [&>p]:mt-[24px] md:[&>p]:mt-[33px]">
              <Markdown input={card.title} />
            </div>
            <p className="body-l-reg mx-auto mb-0 mt-6 max-w-[256px]">
              {card.description}
            </p>
          </div>
          {index !== cards.length - 1 && (
            <div className="mt-[35px] hidden h-[inherit] w-[3px] bg-[#E3E3E3] md:block" />
          )}
        </>
      ))}
    </div>
  )
}
